<template>
  <Auth>
    <div>
      <h1 class="title title--2">
        Merci. Vous allez recevoir
        <br />
        un email de validation.
      </h1>
      <p>
        Vous n'avez rien reçu ?
        <a class="link link--nested" @click="sendVerifyEmail()">
          Renvoyer cet email
        </a>
      </p>
    </div>
  </Auth>
</template>

<script>
import { api } from '@/lib/api';
import { Auth } from '@/components';

export default {
  components: { Auth },

  methods: {
    sendVerifyEmail() {
      api.post('/email_confirmation_tokens', {});
    },
  },
};
</script>
